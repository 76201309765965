import React, { createContext, useState, useEffect } from "react";
import apiRequest from "../api/apiRequest";
import urls from "../api/urls";
import { theme } from "../uikit/theme";

const ConfigurationContext = createContext();

export const ConfigurationProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [gradeBands, setGradeBands] = useState([]);
  const [activities, setActivities] = useState([]);
  const [configurations, setConfigurations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [schools, setSchools] = useState([]);
  const [scripts, setScripts] = useState([]);
  const [addedBanners, setAddedBanners] = React.useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        await fetchConfigurations();
        await fetchSchools();
        await fetchArticlesCategories();
        await fetchActivities();
      } catch {
        console.error("Error loading application configurations");
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: urls.activities,
      });
      setActivities(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const isFeatureEnabled = featureTag => {
    if (!configurations.features) return false;
    const features = configurations.features;
    const foundFeature = features.find(f => f.tag === featureTag && f.type === "user-facing");
    if (!foundFeature) return false;
    return foundFeature.is_frontend_useable;
  };

  const getFeatureName = tag => {
    if (!configurations.features) return "";
    const features = configurations.features;
    const foundFeature = features.find(f => f.tag === tag && f.type === "user-facing");
    if (!foundFeature) return "";
    return foundFeature.display_name;
  };

  const getFeatureRedirect = tag => {
    if (!configurations.features) return null;
    const features = configurations.features;
    const foundFeature = features.find(f => f.tag === tag && f.type === "user-facing");
    if (!foundFeature) return null;
    return foundFeature.redirect_to;
  };

  const fetchSchools = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: urls.outcomes,
      });
      setSchools(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const fetchArticlesCategories = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: urls.articlesCategories,
      });
      setCategories(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const fetchConfigurations = async () => {
    try {
      if (localStorage.configurations) {
        const configs = JSON.parse(localStorage.getItem("configurations"));
        setGradeBands(configs.gradeBands);
        setConfigurations(configs);
        setScripts(configs.scripts);
      }

      const response = await apiRequest({
        method: "get",
        url: urls.configurations,
      });
      localStorage.setItem("configurations", JSON.stringify(response.data));
      setGradeBands(response.data.gradeBands);
      setConfigurations(response.data);
      applyConfigurations(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const applyConfigurations = async configurations => {
    if (configurations?.programName) document.title = configurations.programName;
    if (configurations.color2) theme.palette.primary.main = configurations.color2;
    if (configurations.color1) theme.palette.secondary.main = configurations.color1;
    if (configurations.color2) theme.palette.secondary.dark = configurations.color2;
    if (configurations.color3) theme.palette.warning.main = configurations.color3;

    if (configurations.custom_css) {
      setTimeout(() => {
        const style = document.createElement("style");
        style.innerHTML = configurations.custom_css;
        document.head.appendChild(style);
      }, [500]);
    }

    if (!addedBanners) {
      let banner1 = null;
      let banner2 = null;
      if (configurations.top_banner_link_1_content && configurations.top_banner_link_1_url) {
        banner1 = createTopBanner(
          configurations.top_banner_link_1_content,
          configurations.top_banner_link_1_url
        );
      }
      if (configurations.top_banner_link_2_content && configurations.top_banner_link_2_url) {
        banner2 = createTopBanner(
          configurations.top_banner_link_2_content,
          configurations.top_banner_link_2_url,
          true
        );
      }
      if (banner1 || banner2) {
        let topBanner = document.createElement("div");
        topBanner.classList.add("top-banner-container");
        if (banner1) topBanner.appendChild(banner1);
        if (banner2) topBanner.appendChild(banner2);
        document.body.prepend(topBanner);
        setAddedBanners(true);
      }
    }
  };

  const createTopBanner = (content, url, second) => {
    //create a div element to hold the banner
    const banner = document.createElement("a");
    banner.href = url;
    banner.classList.add("top-banner");
    banner.target = "_blank";
    banner.style.backgroundColor = configurations.color1;
    if (second) {
      banner.style.backgroundColor = configurations.color2;
    }
    banner.innerHTML = content;
    return banner;
  };

  return (
    <ConfigurationContext.Provider
      value={{
        categories,
        gradeBands,
        configurations,
        activities,
        schools,
        isLoading,
        scripts,
        error,
        fetchConfigurations,
        fetchSchools,
        fetchActivities,
        fetchArticlesCategories,
        isFeatureEnabled,
        getFeatureName,
        getFeatureRedirect,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationContext;
